import type { LoginCredentials, SignupCredentials, User } from '~/types/index'

/**
 * @file This store is responsible for managing the auth state.
 *
 * @todo
 */

export const useAuthStore = defineStore('auth', () => {
  const user = useCookie<User | null>('user')

  const form = ref<LoginCredentials | SignupCredentials>({
    email: '',
    password: '',
  })

  const isAuth = computed(() => !!user.value || useCookie('XSRF-TOKEN').value)

  async function logout() {
    await useFetchApi('/logout', { method: 'POST' })
    user.value = null
    useCookie('XSRF-TOKEN').value = ''
    navigateTo('/login')
  }

  async function getAuth() {
    const { data, error } = await useFetchApi('/user')
    user.value = data.value as User

    if (error.value)
      $revoke()

    return data
  }

  async function login(credentials: LoginCredentials) {
    await useFetchCsrf()

    const login = await useFetchApi('/login', {
      method: 'POST',
      body: credentials,
    })

    if (!login.error.value)
      await getAuth()

    return login
  }

  async function signup(info: SignupCredentials) {
    await useFetchCsrf()

    const signup = await useFetchApi('/register', {
      method: 'POST',
      body: info,
    })

    if (!signup.error.value)
      await getAuth()

    return signup
  }

  function $revoke() {
    // remove user's session
    user.value = null
    useCookie('XSRF-TOKEN').value = ''
  }

  return {
    user,
    form,
    isAuth,

    login,
    getAuth,
    logout,
    signup,
    $revoke,
  }
})
